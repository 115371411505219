<template>
    <b-row class="match-height">
      <b-col
lg="8"
offset="2"
> <b-card
      title="Edit Stock Opname"
    >
            <b-form
        @submit.prevent="submitEditStockOpname"
>
        <b-row>
          <b-col cols="12">
            <b-button
              type="button"
              variant="primary"
              class="mr-1"
              @click="previous()"
            >
              <feather-icon
                icon="ArrowLeftIcon"
                size="16"
                class="align-middle"
              />
              Back
            </b-button>
            <div class="devider" />
            </b-col>
        <b-col cols="12">
            <b-form-group
              label="Date *"
              label-cols-md="12"
            >
            <b-form-datepicker
              v-model="stockOpnameDate"
/>
            </b-form-group>
          <b-form-group>
            <label for="no_invoice">Description *</label>
              <b-form-input
                id="description"
                v-model="stockOpnameDescription"
              />
          </b-form-group>
          <b-form-group>
            <label for="no_invoice">Hour *</label>
              <b-form-input
                id="hour"
                v-model="stockOpnameHour"
              />
          </b-form-group>
        <b-form-group>
          <label for="no_invoice">Items :</label>
        </b-form-group>
        <b-form-group>
          <br>
           <div v-if="stockOpnameIsConfirmed===false">
          <b-row>
            <b-col
cols="6"
class="text-center font-weight-bold"
>
              Item name
            </b-col>
            <b-col
cols="6"
class="text-center font-weight-bold"
>
              QTY
            </b-col>
            </b-row>
           <p />
          <b-row
v-for="(item) in items"
:key="item.stock_opname_detail_item_id"
>
          <br>
            <b-col
cols="6"
class="text-center"
>
                <b-list-group>
                  <b-list-group-item href="#">
{{ item.item.item_name }} ({{ item.item.item_code }})
</b-list-group-item>
                </b-list-group>
              </b-col>
            <b-col
cols="6"
class="text-center"
>
            <b-form-input
              v-model="item.stock_opname_detail_item_qty"
              class="text-center"
              />
            </b-col>
            <b-col cols="12">
              <br>
            </b-col>
            </b-row>
        <!-- submit and reset -->
        <b-col offset-md="12">
          <b-button
            type="submit"
            variant="primary"
            class="mr-1"
          >
            Submit
          </b-button>
        </b-col>
            </div>
        </b-form-group>
          </b-col>
    </b-row>
        </b-form>
    </b-card>
      </b-col>
    </b-row>
  </template>
  <script>
  import {
    BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BCard, BListGroup, BListGroupItem, BFormDatepicker,
    } from 'bootstrap-vue'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import axios from 'axios'
  import moment from 'moment'
  import Ripple from 'vue-ripple-directive'
  import { VueHorizontalTimeline } from "vue-horizontal-timeline"

  import { ref } from '@vue/composition-api'

  const VersionCheck = ''
  const items = []
  const chosenItem = ref(3)
  const chosens = []
  const statusItems = []
  const supplier = ''
  const shipmenBy = ''
  const deliveryAddress = ''
  const stockOpnameSchedule = ''
  const billingAddress = ''
  const termOfPayment = ''
  const paymentType = ''
  const stockOpnameIsConfirmed = false
  const stockOpnameIsReceived = false
  export default {
    components: {
        BFormDatepicker,
      VueHorizontalTimeline,
      BListGroup,
      BListGroupItem,
      BCard,
      BRow,
      BCol,
      BFormGroup,
      BFormInput,
      BForm,
      BButton,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
      stockOpnameIsConfirmed,
      stockOpnameIsReceived,
      paymentType,
      statusItems,
      VersionCheck,
      items,
      chosenItem,
      chosens,
      supplier,
      shipmenBy,
      deliveryAddress,
      stockOpnameSchedule,
      billingAddress,
      termOfPayment,
      }
    },
    mounted() {
        this.getDetail()
    },
    methods: {
    getDetail() {
      const userToken = this.$cookies.get('userToken')
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${userToken}`,
  }
      const item = this.$cookies.get('StockOpnameDetail')
      const id = item.stock_opname_id
      this.modalDetailShow = true
      this.stockOpnameBranch = `${item.branch.branch_name}  (${item.branch.branch_code})`
      this.stockOpnameDate = this.dateSimple(item.stock_opname_date)
      this.stockOpnameHour = item.stock_opname_hour
      this.stockOpnameDescription = item.stock_opname_description
      this.stockOpnameIsConfirmed = item.stock_opname_confirmed_by_sender === true ? true : false
      this.stockOpnameIsReceived = item.stock_opname_received_by_receiver === true ? true : false
      this.stockOpnameType = item.stock_opname_type
      this.stockOpnameNotes = item.stock_opname_notes
      axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_STOCK_OPNAME_DETAILS}${id}`, { headers })
      .then(response => {
        if (response.data.success === true) {
          this.items = response.data.data
          this.items.map(o => {
            const big = o.stock_opname_detail_item_qty > o.item.item_current_available ? o.stock_opname_detail_item_qty : o.item.item_current_available
            const small = o.stock_opname_detail_item_qty < o.item.item_current_available ? o.stock_opname_detail_item_qty : o.item.item_current_available
            o.difference = big - small
          })
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Fetching Data success',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
        } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Fetching Data failed',
                icon: 'CheckIcon',
                variant: 'danger',
              },
            })
        }
        })
    },
      getOptionLabel(option) {
        return (option && option.label) || ''
      },
      putItem(val) {
        if (this.chosens.length > 0) {
          let same = false
          this.chosens.map(elem => {
                if (elem.value === val.value) {
                  same = true
                }
          })
          if (same === false) {
              let item = null
              this.items.map(elem => {
                if (elem.value === val.value) {
                  item = { label: elem.label, value: elem.value, qty: 1 }
                }
              })
            this.chosens.push(item)
          }
        } else {
              let item = null
              this.items.map(elem => {
                if (elem.value === val.value) {
                  item = { label: elem.label, value: elem.value, qty: 1 }
                }
              })

            this.chosens.push(item)
        }
      },
      applyItem(val) {
        console.log(val.data)
      },
      getOptionKey(option) {
        return (option && option.value) || ''
      },
      inputSearch(search) {
        console.log(search)
      },
      removeRow(val) {
        this.chosens = this.chosens.filter(obj => (obj.value !== val))
      },
      dateFormat(value, format = 'MM/DD/YYYY') {
          return moment(String(value)).format(format)
      },
    dateSimple(value, format = 'YYYY-MM-DD') {
        let dateRet = ''
        if (value !== null) {
          dateRet = moment(String(value)).format(format)
        } else { dateRet = null }
        return dateRet
    },
    previous() {
          this.$router.push({ name: 'apps-stock-opname-list' })
    },
      numberFormat(value) {
        return parseFloat(value).toFixed(2)
      },

  submitEditStockOpname() {
    const userToken = this.$cookies.get('userToken')
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${userToken}`,
  }

if (this.items.length > 0) {
      const item = this.$cookies.get('StockOpnameDetail')
      const id = item.stock_opname_id
      const body = {
        stock_opname_description: this.stockOpnameDescription,
        stock_opname_hour: this.stockOpnameHour,
        stock_opname_date: this.stockOpnameDate,
        stock_opname_items: JSON.stringify(this.items),
        time: moment(),
      }
    axios
      .post(`${process.env.VUE_APP_API_URL}transactions/stock_opname/edit/${id}`, body, { headers })
      .then(response => {
        if (response.data.success === true) {
          this.chosens = []
          this.transferType = ""
          this.stockOpnameDescription = ""
          this.chosenBranch = null
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Edit Stock Opname Success',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$router.replace({ name: 'apps-stock-opname-list' })
        } else {
        this.$toast({
          component: ToastificationContent,
          props: {
              title: 'Edit Stock Opname Failed',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        }
        })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
              title: 'Edit Stock Opname Failed',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    } else {
      this.$toast({
          component: ToastificationContent,
          props: {
            title: "Please select items",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        })
    }
  },
    },
  }
  </script>